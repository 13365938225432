import types from './mutation-types';

export default {
  [types.UPDATE_BET_TICKER_LIST](state, data) {
    state.betTickerList = data;
  },
  [types.SET_BET_TICKER_LIST_LOADING](state, isLoading) {
    state.betTickerListLoading = isLoading;
  },
  [types.SET_ACTIVE_SIDEBAR_OPTION](state, sidebarOption) {
    state.activeSidebarOption = sidebarOption;
  },
  [types.SET_SELECTED_SEARCH_FIELD](state, field) {
    state.selectedSearchField = field;
  },
  [types.UPDATE_BET_TICKER_SEARCH](state, value) {
    state.betTickerListSearch = value;
  },
  [types.SET_BET_TICKER_CONFIGURATIONS](state, value) {
    state.betTickerConfigurations = value;
  },
  [types.SET_ALL_SPORTS_DISPLAY_MARKETS_CONFIGURATION](state, value) {
    state.allSportsMarketDisplayConfigurations = value;
  },
  [types.SELECT_BET_TICKER](state, value) {
    state.selectedBetTicker = value;
  },
  [types.SHOW_DISABLED_TICKERS](state, showDisabledTickers) {
    state.showDisabledTickers = showDisabledTickers;
  },
  [types.SET_ONLY_FLAGGED_BETS](state, onlyFlaggedBets) {
    state.onlyFlaggedBets = onlyFlaggedBets;
  },
  [types.UPDATE_LIABILITY_EVENTS](state, events) {
    state.liabilityEvents = events;
  },
  [types.SET_LIABILITY_EVENTS_LOADING](state, value) {
    state.liabilityEventsLoading = value;
  },
  [types.UPDATE_LIABILITY_FILTERS_META](state, { sports, competitions }) {
    if (!sports) {
      state.liabilityFiltersMeta.competitions = competitions;
      return;
    }
    state.liabilityFiltersMeta = {
      sports,
      competitions,
    };
  },
  [types.UPDATE_LIABILITY_EVENT](state, value) {
    state.liabilityEvent = value;
  },
  [types.SET_LIABILITY_EVENT_LOADING](state, value) {
    state.liabilityEventLoading = value;
  },
  [types.SET_LIABILITY_DISPLAY_MARKETS_CONFIGURATION](state, value) {
    state.liabilityEventMarketsDisplayConfiguration = value;
  },
  [types.SET_LIABILITY_EVENT_MARKETS](state, value) {
    state.liabilityMarketsObject = value;
  },
  [types.SET_LIABILITY_EVENT_SELECTIONS_BY_MARKET_ID](state, value) {
    state.liabilityMarketSelectionsObject = value;
  },
  [types.SET_LIABILITY_MARKET_GROUPS](state, value) {
    state.marketGroups = value;
  },

  [types.SET_ALL_CUSTOMERS_IS_INITIALIZING](state, isInitializing) {
    state.allCustomers.isInitializing = isInitializing;
  },
  [types.SET_ALL_CUSTOMERS_IS_LOADING](state, isLoading) {
    state.allCustomers.isLoading = isLoading;
  },
  [types.SET_ALL_CUSTOMERS_INITIALIZED](state, initialized) {
    state.allCustomers.initialized = initialized;
  },
  [types.SET_ALL_CUSTOMERS_FILTERS](state, filters) {
    state.allCustomers.filters = filters;
  },
  [types.SET_ALL_CUSTOMERS_PAGE](state, page) {
    state.allCustomers.page = page;
  },
  [types.SET_ALL_CUSTOMERS_LIMIT](state, limit) {
    state.allCustomers.limit = limit;
  },
  [types.SET_ALL_CUSTOMERS_COLUMNS](state, columns) {
    state.allCustomers.columns = columns;
  },
  [types.SET_ALL_CUSTOMERS_TOTAL_COUNT](state, totalCount) {
    state.allCustomers.totalCount = totalCount;
  },
  [types.SET_ALL_CUSTOMERS_DATA](state, data) {
    state.allCustomers.data = data;
  },
  [types.SET_ALL_CUSTOMERS_SIDEBAR](state, sidebar) {
    state.allCustomers.sidebar = sidebar;
  },
  [types.SET_CUSTOMER_IS_INITIALIZING](state, isInitializing) {
    state.customer.isInitializing = isInitializing;
  },
  [types.SET_CUSTOMER_IS_LOADING](state, isLoading) {
    state.customer.isLoading = isLoading;
  },
  [types.SET_CUSTOMER_KEY](state, key) {
    state.customer.key = key;
  },
  [types.SET_CUSTOMER_DATA](state, data) {
    state.customer.data = data;
  },
  [types.SET_CUSTOMER_SIDEBAR](state, sidebar) {
    state.customer.sidebar = sidebar;
  },
  [types.SET_CUSTOMER_EDIT_NICKNAME](state, editNickname) {
    state.customer.editNickname = editNickname;
  },
  [types.SET_CUSTOMER_BETS_IS_LOADING](state, isLoading) {
    state.customer.bets.isLoading = isLoading;
  },
  [types.SET_CUSTOMER_BETS_FILTERS](state, filters) {
    state.customer.bets.filters = filters;
  },
  [types.SET_CUSTOMER_BETS_PAGE](state, page) {
    state.customer.bets.page = page;
  },
  [types.SET_CUSTOMER_BETS_LIMIT](state, limit) {
    state.customer.bets.limit = limit;
  },
  [types.SET_CUSTOMER_BETS_COLUMNS](state, columns) {
    state.customer.bets.columns = columns;
  },
  [types.SET_CUSTOMER_BETS_TOTAL_COUNT](state, totalCount) {
    state.customer.bets.totalCount = totalCount;
  },
  [types.SET_CUSTOMER_BETS_DATA](state, data) {
    state.customer.bets.data = data;
  },
  [types.SET_CUSTOMER_NOTES_IS_INITIALIZING](state, isInitializing) {
    state.customer.notes.isInitializing = isInitializing;
  },
  [types.SET_CUSTOMER_NOTES_IS_LOADING](state, isLoading) {
    state.customer.notes.isLoading = isLoading;
  },
  [types.SET_CUSTOMER_NOTES_ORDER_BY](state, orderBy) {
    state.customer.notes.orderBy = orderBy;
  },
  [types.SET_CUSTOMER_NOTES_FIRST](state, first) {
    state.customer.notes.first = first;
  },
  [types.SET_CUSTOMER_NOTES_TOTAL_COUNT](state, totalCount) {
    state.customer.notes.totalCount = totalCount;
  },
  [types.SET_CUSTOMER_NOTES_DATA](state, data) {
    state.customer.notes.data = data;
  },
};
