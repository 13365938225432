export default {
  latestRoute: null,
  columns: {},
  search: '',
  selectedRowSpacingOption: 'compact-view',
  totalRowsCount: 0,
  operatorsList: [],

  eventListFilterModifiers: {
    date: [
      {
        value: 'equalTo',
        label: 'Is',
      },
      {
        value: 'greaterThan',
        label: 'After',
      },
      {
        value: 'lessThan',
        label: 'Before',
      },
    ],
    select: [
      {
        value: 'in',
        label: 'Is',
      },
      {
        value: 'notEqualToInsensitive',
        label: 'Is not',
      },
    ],
  },
  previousEventListOptions: '',
  eventList: [],
  eventListLoading: false,
  bookedEventsInSession: [],
  bookedEvents: [],
  selectedEvents: [],
  selectedMarkets: [],
  isEventsSidebarActive: false,
  isEventsFiltersActive: true,

  sidebar: {
    expanded: true,
    loading: false,
    sports: [],
    regions: [],
    competitions: [],
    selection: {
      sportId: null,
      regionId: null,
      competitionId: null,
      subscribed: null,
    },
  },
  subscriptionModal: {
    open: false,
    type: '',
    id: '',
  },

  event: null,
  eventLoading: false,
  eventMarkets: null,
  eventMarketsLoading: false,
  eventTeamPlayers: {
    home: [],
    away: [],
  },

  marketTemplate: {
    selectedSport: '',
    selectedRegion: '',
  },
  sportTemplates: [],
  competitionTemplateChanged: false,
  userToken: null,
  username: '',
  userRoles: [],
  operator: '',
  loginErrorMessage: '',
  socketConnection: null,
  changePasswordAttributes: null,

  allFeeds: [],

  notifications: [],
  suspensionInProgressEventIds: [],
  suspendMarketTypeActionLoading: false,
  eventListMappingEvent: null,

  marketGroups: [],
  marketFavorites: [],

  mapping: {
    initialized: false,
    loading: false,
    sports: [],
    entries: [],

    selectedSportId: '',
    selectedSportLabel: '',

    selectedFeed: 'huddle',
    primaryFeeds: [],
    secondaryFeeds: [],
    feedsPerSport: [],
    displayedFeeds: [],

    searchOptions: [
      {
        label: 'name',
        value: 'name',
      },
      {
        label: 'ID',
        value: 'id',
      },
      {
        label: 'Index',
        value: 'index',
      },
    ],
    selectedSearchOption: 'name',
    searchQuery: '',

    selectedDisplay: ['mapped', 'not-mapped'],
    displayOptions: [
      {
        id: 'mapped',
        label: 'Mapped',
      },
      {
        id: 'not-mapped',
        label: 'Not mapped',
      },
    ],

    selectedDate: 7,
    dateOptions: [
      {
        label: 'Last month',
        value: -30,
      },
      {
        label: 'Last 7 days',
        value: -7,
      },
      {
        label: 'Last 3 days',
        value: -3,
      },
      {
        label: 'Yesterday',
        value: -1,
      },
      {
        label: 'Today',
        value: 0,
      },
      {
        label: 'Incoming 2 hours',
        value: 0.2,
      },
      {
        label: 'Incoming 3 days',
        value: 3,
      },
      {
        label: 'Incoming 7 days',
        value: 7,
      },
      {
        label: 'Next month',
        value: 30,
      },
      {
        label: 'Next 3 months',
        value: 90,
      },
    ],
  },
  eventSuggestedMappings: [],
  competitorSuggestedMappings: [],
  regionSuggestedMappings: [],
  competitionSuggestedMappings: [],
  playerSuggestedMappings: [],

  manualResultingEvent: null,
  manualResultingEventLoading: false,
  manualResultingEvents: [],
  manualResultingEventMarkets: {},
  manualResultingEventMarketsLoading: false,

  // Player setup
  playerSetupOriginalTableData: {},
  playerSetupTableData: null,
  playerSetupData: null,
  isPlayerSetupDataLoading: {},
  isPlayerSetupProjectionsAvailable: false,
  isPlayerSetupScoreboardActive: false,
  playerSetupEventSearchActive: false,
  playerSetupEventList: [],
  playerSetupEventListLoading: false,
  teamSquadListLoading: false,
  isPlayerSetupSubmitEnabled: false,
  isPlayerSetupSimulateEnabled: false,
  isPlayerSetupSimulateBySocketChangeEnabled: false,
  isPlayerSetupPageFrozen: false,
  frozenPlayerSetupPageMessage: '',
  isPlayerSetupSimulateBtnFrozen: false,
  numberOfSimulateAttempts: 0,
  availableMarkets: {},
  isMarketsLoading: {},
  playersForMarketsPopupList: [],
  highlightedPlayer: null,
  playerSetupInferActive: false,
  playerSetupBeforeInferState: null,
  playerParamsSelectedMode: '',
  paramsManagerMarkets: [],
  paramsManagerMarketsBeforeInferState: null,
  lineupPresetsByTeam: {},
  selectedLineupPresetByTeam: {},
  playerSetupMappedPlayersForProjections: [],
  paramsManagerSquadData: [],
  paramsManagerEventSuspendLoading: false,
  availableMarketsPlayerTypes: {},
  playerSetupUnpublishedChanges: false,

  // Game params
  playerSetupGameParams: null,
  gameParamsSelectedMode: '',
  gameParamsLastSubmittedSelectedMode: '',
  paramsManagerGameProjectionsAvailable: '',
  allBaseballParks: [],
  gameParamsSelectedMarkets: null,
  gameParamsSelectedMarketsProcessing: false,

  // Trading UI
  tradingEvent: null,
  tradingEventLoading: false,
  originalTradingPlayerProps: [],
  lastSimulateTradingPlayerProps: [],
  tradingPlayerProps: null,
  isSimulateBtnEnabled: false,
  isSimulateBtnLoading: false,
  isPublishBtnEnabled: false,
  isPublishBtnLoading: false,
  tradingPlayerPropsLoading: false,
  tradingEventSearchActive: false,
  tradingEventList: [],
  tradingEventListLoading: false,
  tradingEventMarkets: {},
  tradingEventSuspendLoading: false,
  selectedTradingMarkets: [],
  isTradingUIPageFrozen: false,
  isTradingUISimulateBtnFrozen: false,
  isUnsavedChangesActive: false,
  tradingSquad: [],
  tradingGameParamsLoading: false,
  tradingEventFeeds: [],
  tradingEventSelectedFeed: null,
  tradingSimulateBySocketChangeEnabled: false,

  oddFormats: [ // Will move this to CMS config when implemented
    {
      id: 'AMERICAN',
      name: 'american',
      label: 'American',
    },
    {
      id: 'DECIMAL',
      name: 'decimal',
      label: 'Decimal',
    },
    {
      id: 'FRACTIONAL',
      name: 'fractional',
      label: 'Fractional',
    },
    {
      id: 'PROBABILITY',
      name: 'probability',
      label: 'Probability',
    },
  ],
  selectedOddFormat: {
    id: 'AMERICAN',
    name: 'american',
    label: 'American',
  },

  // Manual edit
  manualEditTableColumns: null,
  manualEditTableData: [],
  manualEditDataLoading: false,
  manualEditSelectedSport: '',
  manualEditSelectedRow: null,
  manualEditPlayerSearchList: [],
  manualEditPlayerSearchListLoading: false,
  manualEditSearch: '',
  manualEditSelectedFeed: '',
  manualEditIsCreateNewRecordActive: false,

  displayMarketsConfiguration: null,
  allMarketParameters: null,
  // Market group
  marketGroupMarketCodeSearchList: [],
  marketGroupMarketCodeSearchListLoading: false,

  // Multiview
  multiviewList: {
    latestOptions: null,
    loading: false,
    eventsById: {},
    competitionsById: {},
    markets: {},
    paramsLoading: false,
  },
  multiviewDrawer: {
    event: null,
    loading: false,
    processing: false,
  },
  multiviewOriginalPlayerParams: null,
  multiviewPlayerParams: null,
  multiviewPlayerParamsLoading: false,
  multiviewLastSimulatePlayerParams: null,
  multiviewSquad: null,
  multiviewSimulateBtnEnabled: false,
  multiviewSimulateBtnFrozen: false,
  multiviewSimulateBtnLoading: false,
  multiviewSubmitBtnEnabled: false,
  multiviewSubmitBtnLoading: false,
  multiviewDrawerFrozen: false,
  multiviewNumberOfSimulateAttempts: false,
  multiviewSimulateBySocketChangeEnabled: false,

  // Odds checker
  oddsCheckerFiltersMeta: {
    sports: [],
    competitions: [],
  },
  oddsCheckerEvents: {},
  oddsCheckerEventsLoading: false,
  oddsCheckerQueryOptions: {},
  // Audit log
  auditLogEvent: null,
  auditLogEventLoading: false,
  auditLogEventMarkets: {},
  auditLogEventMarketSelectionsUrls: null,
  auditLogMarketHistoryDashboardLoading: false,

  // Market templates
  marketTemplateMarketsScrollPosition: 0,

  competitionsSubscriptionsFilters: {
    subscription: null,
    mapping: null,
  },
};
